<template>
    <div>
        <h3>Feedback Lists</h3>
        <el-table :data="items" border v-loading="loading">
            <el-table-column prop="patient" label="Patient">
                <template #default="scope">
                    <div>
                        <p class="m-0">{{  scope.row.patient_name }}</p>
                        <p class="m-0">{{  scope.row.patient_number }}</p>
                        <p class="m-0">{{  scope.row.patient_location }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="feedback_type" label="Feedback type"/>
            <el-table-column prop="rating" label="Rating"/>
            <el-table-column prop="service_improvement" label="Service improvement"/>
            <el-table-column prop="comment" label="Comment"/>
            <el-table-column prop="created_at" label="Created date"/>
        </el-table>
        <el-pagination
            v-model:current-page="currentPage"
            :page-size="per_page"
            layout="total, prev, pager, next"
            :total="total_count"
            @current-change="getItems"
        />
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'FeedbackList',
    data(){
        return {
            items: [],
            total_count: 0,
            currentPage: 0,
            per_page: 20,
            loading: false,
        }
    },
    created(){
        this.getItems();
    },
    methods: {
        getItems(val){
            if (val) {
                const offset = val - 1;
                var correctValue = offset * this.per_page;
            }
            this.loading = true;
            axios.get(`/api/v2/user/service/feedbacks?limit=${this.per_page}&offset=${correctValue || 0}`)
            .then(res => {
                this.loading = false;
                this.items = res.data.data;
                this.total_count = res.data.total_count;
            })
        }
    }
}
</script>
<style scoped>
.m-0 {
    margin: 0;
}
</style>